@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&family=Titillium+Web&display=swap');
@import './Components/Theming/Themes/themeLight.css';
@import './Components/Theming/Themes/themeDark.css';
@import './CSS/grid.scss';

.App {
  text-align: center;
}

.textUpcoming {
  float: right;
  color: #1976d2;

  justify-content: end;
}

.navText {
  font-size: 20px;
  /* color: #black; */
}

.palaceImage {
  position: absolute;
  width: 800px;
  /* top: 245px; */
  top: 200px;
  /* right: 300px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.background {
  background: #f3f3f63d;
}

.borderGrey {
  border: 1px solid #80808017;
}

.borderGreyLandingPage {
  /* border-top: 1px solid; */
  border-right: 1px solid #80808017;
  border-left: 1px solid #80808017;
  border-bottom: 1px solid #80808017;
}

/* .nav-link{
  color: white !important;
} */
.modal-backdrop {
  z-index: 1040 !important;
}

.holderCardsGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.cardOne {
  width: 100%;

  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  /* border-color: #eeeeee; */
  border-color: #0187ce;

  border-radius: 20px;
  /* box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 1px 5px 0 #0187ce; */
  text-align: center;
  padding: 20px;

  flex-grow: 0;
  flex-shrink: 1;

  min-height: 350px;
  max-height: 350px;
  max-width: 350px;
}

.cardThree {
  border-width: 1px;
  border-style: solid;
  /* border-color: #eeeeee; */
  border-color: #0187ce;
  display: grid;

  border-radius: 20px;
  /* box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 1px 5px 0 #0187ce; */

  text-align: center;
  padding: 20px;

  flex-grow: 0;
  flex-shrink: 1;

  width: 350px !important;
  min-height: 350px;
  /* max-height: 350px; */
  max-width: 350px;
}

.cardFour {
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #0187ce;
  border-radius: 20px;
  /* box-shadow: 0 2px 9px 0 rgb(0 0 0 / 10%); */
  /* box-shadow: 0 1px 5px 0 #0187ce; */
  text-align: center;
  padding: 80px;
  flex-grow: 0;
  flex-shrink: 1;
}

.cardHeader {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* height: 50px; */
  /* width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; */
}

.cardBody {
}

/* Timeline Roadmap Section */
.wrapper {
  scroll-behavior: smooth;
  position: relative;
  /* background: #61dafb; */
  /* box-shadow: 0 2px 9px 0 rgb(0 0 0 / 10%); */
}

.wrapper .rowTimeLine {
  display: flex;
}

.wrapper .rowTimeLine1 {
  justify-content: flex-start;
}

.wrapper .rowTimeLine2 {
  justify-content: flex-end;
}

.wrapper .rowTimeLine section {
  background: white;
  width: calc(50% - 40px);
  border: 1px;
  border-style: solid;
  /* border-color: #353535; */
  border-color: #0187ce;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  z-index: 0;
  /* box-shadow: 0 2px 9px 0 rgb(0 0 0 / 10%); */
  /* box-shadow: 0 1px 5px 0 #0187ce; */
}

.wrapper .rowTimeLine section::before {
  position: absolute;
  top: 28px;
  content: '';
  height: 15px;
  width: 15px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
  border: 1px;
  border-style: solid;
  border-color: #0187ce;
  /* box-shadow: 0 2px 9px 0 rgb(0 0 0 / 10%); */
  /* box-shadow: 0 1px 5px 0 #0187ce; */
}

.rowTimeLineIconCenter {
  position: absolute;
  background: white;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  /* border: 1px solid; */
  /* border-color: black; */
  text-align: center;
  color: black;
  top: 15px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.rowTimeLine1 section .rowTimeLineIconCenter {
  right: -60px;
}

.rowTimeLine2 section .rowTimeLineIconCenter {
  left: -60px;
}

.rowTimeLine1 section::before {
  right: -7px;
}

.rowTimeLine2 section::before {
  left: -7px;
}

.rowTimeLine section .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowTimeLine section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowTimeLine section .details .title {
  font-size: 25px;
}

.rowTimeLine section p {
  text-align: left;
  margin: 10px 0 17px 0;
}

.rowTimeLine section .bottom a {
  text-decoration: none;
  background: #353535;
  color: #eeeeee;
  padding: 7px 15px;
}

.fontZeroMargin {
  margin: 0px !important;
}

.centerLine {
  position: absolute;
  height: 100%;
  width: 4px;
  background: black;
  left: 50%;
  top: 20px;
  transform: translate(-50%);
}

.centerLine .scrollIcon {
  position: absolute;
  background: white;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: black;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.centerLine .scrollIcon {
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

/* Timeline Roadmap Section */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loaderAbsolute {
  position: absolute;
  top: 8%;
  right: 3%;
  z-index: 1030;
}

/* .header {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  z-index: 1;
  color: #353535;
  width: 100%;
  padding: 1.5rem 2rem;
}

.headerContainer {
  
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.mobileMenuBtn {
  color: rgba(0, 0, 0, 0.55) !important;
  background-color: #0d6efd00 !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  outline: none !important;
}

.mobileMenuBtn.btn-primary:focus {
  box-shadow: none;
}

.mobileMenuBtnIconCSS {
  /* font-size: 24px; */
  width: 1.5em;
  height: 1.5em;
  font-size: 1.25rem;
}

.landingPageBg {
  height: 100vh;
  /* background-image: url('Assets/bgA.png'); */
  background-size: auto;
  background-size: cover;
}

.landingPageIntro {
  padding: 3rem 0rem;
  align-items: center;
}

.page {
  min-height: 990px;
  height: auto;
  /* height: calc(100vh - 78px); */
  /* max-height: 1000px; */
  padding: 3rem 0rem;
  min-height: auto;
  display: flex;
  flex-direction: column;
}
.pageBackground {
  /* background: rgb(242, 243, 250);
  background: linear-gradient(
    180deg,
    rgba(242, 243, 250, 1) 0%,
    rgba(251, 249, 249, 1) 20%
  ); */
  /* background: linear-gradient(
    180deg,
    rgba(202, 200, 211, 1) 0%,
    rgba(235, 233, 243, 1) 5%,
    rgba(246, 246, 252, 1) 37%,
    rgba(235, 236, 244, 1) 90%
  ); */
  /* background: rgb(217, 215, 215);
  background: linear-gradient(
    180deg,
    rgba(217, 215, 215, 1) 0%,
    rgba(217, 215, 215, 1) 40%, */
  /* rgba(245, 251, 239, 1) 0%,
    rgba(225, 230, 220, 1) 35%,
    rgba(226, 231, 221, 1) 37%,
    rgba(255, 255, 255, 1) 100% */
  /* ); */
}

.landingPageIntroInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
}

.landingPageIntroInnerLogo {
  width: 100%;
}

.paragraphHeader {
  text-align: left;
  font-size: 36px;
  font-weight: 900;
  float: left;
}

.modalHeader {
  padding: 10px;
}

.modalBody {
  padding: 10px;
}

.paragraphHeaderLarge {
  text-align: left;
  font-size: 48px;
  font-weight: 900;
  float: left;
}

.paragraphText {
  text-align: left;
  float: left;
  word-break: break-word;
}

.fontIntroBig {
  font-size: 86px;
}

.font {
  font-size: 25px;
}

.fontTwenty {
  font-size: 20px;
}

.fontSixTeen {
  font-size: 18px;
}

.breaker {
  padding-top: 10px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop30 {
  margin-top: 30px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingTop30 {
  padding-top: 30px;
}

.paddingTop100 {
  padding-top: 100px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft100 {
  margin-left: 100px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.paddingLeft100 {
  padding-left: 100px;
}

.imgGeneric {
  padding-top: 100px;
}

.displayGrid {
  display: grid;
}

.listGroup {
}

.itemHeader {
  float: left;
  width: 60%;
  text-align: left;
  font-size: 25px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.itemHeaderRight {
  float: right;
  width: 40%;
  text-align: right;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  font-size: 25px;
}

.itemBody {
  text-align: left;
  font-size: 25px;
}

.item {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  line-height: 2;
}

.itemNoBorder {
  /* display: flex; */
  gap: 10px;
  width: 100%;
  line-height: 2;
}

.itemDivider {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  line-height: 2;
}

/* Tokenomics */
.marginLeftStreams {
  margin-left: 20px;
}

.textAlignCenter {
  text-align: center;
}

/* .container{
  display: flex;
  justify-content: center;
} */
.container {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

.containerList {
  display: inline-block;
  text-align: left;
}

.note {
  font-size: 20px;
  color: #353535;
}

.inlineGrid {
  display: inline-grid;
}

.footerLogo {
  box-sizing: border-box;
  height: 100px;
}

.footerTextSectionLeft {
  text-align: left;
  float: left;
  line-height: 2;
}

.boxContainer {
  display: inline-grid;
  float: left;
  padding-left: 75px;
  /* width: 30%; */
}

.grid {
  display: grid;
}

.flexColum {
  display: flex;
  flex-direction: column;
}

.footerSectionHeaderHeight {
  height: 50px;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.hideImgExceptMob {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .componentContainer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: 20vw;
  margin-left: 20vw;
} */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .componentContainer {
    /* --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0; */
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    /* padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: 20vw;
    margin-left: 20vw; */
  }
  .landingPageIntro {
    padding-top: 180px;
    /* justify-content: center; */
  }

  .landingPageIntroFirstPage {
    padding-top: 50px;
    /* justify-content: center; */
  }

  .landingPageIntroInnerLogo {
    width: 100%;
  }
}

/* https://www.youtube.com/watch?v=-uzOuGlUfDQ */
/* Small devices (tablets, 992 and down) */
@media only screen and (max-width: 992px) {
  .cardOne {
    max-width: 100%;
    /* max-width: 450px; */
  }

  .wrapper .centerLine {
    top: 60px;
    left: 30px;
  }

  .wrapper .rowTimeLine {
    margin: 30px 0 3px 60px;
  }

  .wrapper .rowTimeLine section {
    width: 100%;
  }

  .rowTimeLine1 section::before {
    left: -7px;
  }

  .rowTimeLine1 section .rowTimeLineIconCenter {
    left: -60px;
  }

  .hideImgExceptMob {
    display: block;
  }

  .hideSM {
    display: none !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cardOne {
    max-width: 100%;
  }

  .title {
    padding-left: 10px;
  }

  .landingPageIntro {
    padding: 48px 2px;
  }

  .wrapper .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .wrapper .centerLine {
    left: 12px;
  }

  .rowTimeLine section::before,
  .wrapper .rowTimeLine .rowTimeLine1 section .rowTimeLineIconCenter {
    /* display: none; */
    /* z-index: 10; */
    /* left: 0px; */
  }

  .rowTimeLine section {
    margin-left: 2px;
  }

  .wrapper .rowTimeLine .rowTimeLine2 section .rowTimeLineIconCenter {
    z-index: 10;
  }

  .rowTimeLine1 section::before {
    left: -15px;
  }

  .rowTimeLine2 section::before {
    left: -15px;
  }

  .rowTimeLine1 section .rowTimeLineIconCenter {
    left: -15px;
  }

  .rowTimeLine2 section .rowTimeLineIconCenter {
    left: -15px;
  }

  .wrapper .rowTimeLine {
    margin: 0px;
  }

  .hideXS {
    display: none !important;
  }

  .hideImgExceptMob {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .landingPageIntro {
    /* min-height: 900px; */
    min-height: 1400px;
  }

  .landingPageIntroInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landingPageIntroInnerLogo {
    width: 80%;
  }

  .cardOne {
    max-width: 450px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .cardOne {
    max-width: 350px;
  }

  .landingPageIntroInner {
    max-width: 1600px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
