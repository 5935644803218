html[data-theme='dark'] {
  --primaryBackground: #202124;
  --primaryTextColor: #ffffff;
  --linkColor: #848484;

  --btnPrimaryTextColor: #848484;
  --btnPrimaryBackground: #202124;

  --btnSecondaryTextColor: #848484;
  --btnSecondaryBackground: #202124;
  --btnSecondaryBorderColor: #848484;
}

/* .nav-link{
    color: var(--linkColor) !important;
}
   */
