html {
  --primaryBackground: #ffffff;
  /* --primaryBackground: #f8f8f1; */
  /* --primaryBackground: #e8ecf2; */
  --primaryTextColor: #030304;
  --linkColor: #030304;
  /* #0e1013 24292f*/
  --btnPrimaryTextColor: #030304;
  --btnPrimaryBackground: #ffffff;

  --btnSecondaryTextColor: #ffffff;
  --btnSecondaryBackground: #468893; /*#0187ce;*/
  --btnSecondaryBorderColor: #ffffff;
  --btnBorderGrey: #859497;
  --btnThirdBackground: #0187ce;
}

/* .background {
  background: rgb(237, 239, 218);
  background: linear-gradient(
    180deg,
    rgba(237, 239, 218, 1) 0%,
    rgba(245, 251, 239, 1) 35%,
    rgba(237, 239, 218, 1) 100%
  );
} */
.nav-link {
  color: var(--linkColor) !important;
}

.btnOne {
  background-color: var(--btnPrimaryBackground) !important;
  color: var(--btnPrimaryTextColor) !important;
  border: 1px solid;
  border-color: var(--btnPrimaryTextColor) !important;
}

/* margin-top: 20px;
background: #5ea0a3; */

/* border: 1px solid black;
cursor: pointer;
height: 50px;
width: 175px;
transition: all;
transition-duration: 250ms;
display: flex;
align-items: center;
justify-content: center;
font-size: 20px;
font-weight: 600;
color: white;
border-radius: 20px; */
.btnTwo {
  margin-top: 20px;
  background-color: var(--btnSecondaryBackground) !important;
  color: var(--btnSecondaryTextColor) !important;
  border: 1px solid !important;
  border-color: var(--btnBorderGrey) !important;
  height: 50px;
  line-height: 1.25;
  width: 175px;
  justify-content: center;
  cursor: pointer;
  transition: all;
  transition-duration: 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 20px;
}

.btnTwo:hover {
  background: #1e3c4e !important;
}
.btnTwo:active {
  background: #1e3c4e !important;
}
.btnTwoColor {
  background-color: var(--btnSecondaryBackground) !important;
  color: var(--btnSecondaryTextColor) !important;
}

/* .btnTwo {
  background-color: var(--btnSecondaryBackground) !important;
  color: var(--btnSecondaryTextColor) !important;
  border: 1px solid !important;
  border-color: var(--btnSecondaryBorderColor) !important;
  height: calc(3.5rem + 2px);
  line-height: 1.25;
  width: 150px;
} */
.btnCommunityLinksReddit {
  background-color: rgb(255, 69, 0) !important;
  color: var(--btnSecondaryTextColor) !important;
  border: 1px solid !important;
  border-color: var(--btnSecondaryBorderColor) !important;
  /* height: calc(3.5rem + 2px); */
  height: 40px;
  line-height: 1.25;
  width: 200px;
}
.btnCommunityLinksDiscord {
  background-color: #5865f2 !important;
  color: var(--btnSecondaryTextColor) !important;
  border: 1px solid !important;
  border-color: var(--btnSecondaryBorderColor) !important;
  /* height: calc(3.5rem + 2px); */
  height: 40px;
  line-height: 1.25;
  width: 200px;
}

.btnCommunityLinksTwitter {
  background-color: rgb(29, 155, 240) !important;
  color: var(--btnSecondaryTextColor) !important;
  border: 1px solid !important;
  border-color: var(--btnSecondaryBorderColor) !important;
  /* height: calc(3.5rem + 2px); */
  height: 40px;
  line-height: 1.25;
  width: 200px;
}

.footer {
  background-color: var(--primaryBackground) !important;
  color: var(--primaryTextColor) !important;
  /* border-top: 1px solid var(--primaryTextColor); */
  box-shadow: 2px 2px 9px 0 rgb(0 0 0 / 10%);
}
