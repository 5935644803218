.pageUnderConstruction {
  padding: 3rem 1rem;
  min-height: 990px;
  height: auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageUnderConstruction {
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 1100px;
  /* z-index: 101; */
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .pageUnderConstruction {
    padding: 3rem 1rem;
    min-height: auto;
    height: 100vh;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
